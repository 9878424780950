import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 200px;
  transform: translate(-50%, -50%);
`;

const Spinner = ({ size = 59.5, color = '#00BCD4', type = 'spinningBubbles' }) => (
  <SpinnerContainer className="d-flex justify-content-center align-items-center">
    <ReactLoading type={type} size={Math.max(size, 4)} color={color} />
  </SpinnerContainer>
);

export default Spinner;