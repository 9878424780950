import React from 'react';
import styled from 'styled-components';
import Spinner from './spinner';

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const LoadingWrapper = () => {
  return (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );
}
 
export default LoadingWrapper;